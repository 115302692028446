import styled from 'styled-components';

export const WideWrapper = styled.div`
  @media (min-width: 800px) {
    position: fixed;
    width: 100%;
    min-height: ${({ pageDesign }) =>
      pageDesign === `design2` ? '90px' : '60px'};
    background-color: #fff;
    left: 0;
    z-index: 998;
  }
`;

export const Wrapper = styled.div`
  min-height: ${({ pageDesign }) =>
    pageDesign === `design2` ? '90px' : '60px'};
  background-color: #fff;
  @media (min-width: 800px) {
    &.sticky > div {
      position: fixed;
      width: calc(100% - 30px);
      background-color: #fff;
      z-index: 999;
    }
  }
`;

export const Container = styled.div`
  padding: 15px;
  max-width: 1150px;
  display: flex;
  margin: 0 auto;
  position: relative;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Logo = styled.div`
  flex: 0 0 25%;

  @media (max-width: 1000px) {
    flex: 0 0 35%;
  }
  @media (max-width: 500px) {
    flex: 0 0 100%;
  }
  & > svg,
  & > picture > img {
    height: 100%;
    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
              @media (min-width: 1024px) {
                height: 50px;
              }
              @media (max-width: 1023px) {
                height: 30px;
              }
              `;
        default:
          return ``;
      }
    }}
  }
`;

export const TrustPilot = styled.div`
  flex: 0 0 ${({ pageDesign }) => (pageDesign === `design2` ? '25%' : '20%')};
  margin-top: 5px;

  @media (max-width: 1024px) {
    flex: 0 0 25%;
  }

  @media (max-width: 1000px) {
    flex: 0 0 35%;
    margin-top: 5px;
  }

  @media (max-width: 500px) {
    flex: 0 0 100%;
    margin-top: 10px;
  }

  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `margin-top: 25px;`;
    }
  }}
`;

export const Nav = styled.div`
  flex: 0 0 ${({ pageDesign }) => (pageDesign === `design2` ? '50%' : '55%')};
  margin-top: 5px;

  @media (max-width: 1024px) {
    flex: 0 0 50%;
  }

  @media (max-width: 1000px) {
    display: none;
    &.active {
      display: block;
      flex: 0 0 100%;
    }
  }

  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `margin-top: 30px;`;
    }
  }}
`;

export const NavLinks = styled.div`
  text-align: right;
  & a {
    font-size: 17px;
    &:hover {
      text-decoration: underline;
      color: ${({ pageDesign }) =>
        pageDesign === `design2` ? '#BC2E3E' : '#e26447'};
    }
  }
  @media (max-width: 1000px) {
    position: absolute;
    z-index: 1;
    background: #fff;
    width: 100%;
    left: 0;
    top: 60px;
    padding-bottom: 10px;
    & a {
      display: block;
      text-align: left;
      padding-left: 25px;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
  @media (max-width: 500px) {
    top: 93px;
  }
`;

export const Link = styled.a`
  padding: 8px 10px;
  text-decoration: none;
  font-size: 20px;
  width: 100%;
`;

export const MobileNav = styled.a`
  display: none;
  position: absolute;
  top: 18px;
  right: 0;
  font-size: 25px;
  @media (max-width: 1000px) {
    display: block;
  }
`;
